import cn from 'classnames';
import * as React from 'react';

import AbstractInput from '../AbstractInput/AbstractInput';

const Textarea = ({ field, ...props }) => (
  <AbstractInput
    {...props}
    {...field}
    component="textarea"
    className={cn('!resize-none', props.className)}
    disabled={props.disabled}
    {...props}
  />
);

export default Textarea;
