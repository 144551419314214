import cn from 'classnames';
import * as React from 'react';
import css from './index.scss';
import { IoIosCheckmarkCircleOutline } from '@react-icons/all-files/io/IoIosCheckmarkCircleOutline';
import { IoIosRadioButtonOff } from '@react-icons/all-files/io/IoIosRadioButtonOff';

const RadioTab = ({
  field: { name, value, onChange, onBlur, checked },
  size = 'md',
  id,
  label,
  className,
  dataCy,
  ...props
}) => (
  <label
    className={cn(css.tab, className, {
      [css.disabled]: props.disabled,
    })}
  >
    <input
      name={name}
      id={id}
      type="radio"
      value={value} // could be something else for output?
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      className="peer absolute opacity-[0.01]"
      {...props}
    />
    <div
      data-cy={dataCy}
      className={cn(
        css.button,
        'leading-0 !p-2 !px-2 !text-left peer-checked:!border-2 peer-checked:!border-solid peer-checked:!border-blue peer-focus:!border-2 peer-focus:!border-solid peer-focus:!border-blue',
        css[`size--${size}`],
        {
          [css.disabled]: props.disabled,
        },
      )}
    >
      <div className="flex w-full items-center gap-2 p-0">
        {props.checked ? (
          <IoIosCheckmarkCircleOutline className="h-6 w-6 flex-none transform text-blue transition duration-150 hover:text-blue-900" />
        ) : (
          <IoIosRadioButtonOff className="h-6 w-6 flex-none transform text-default transition duration-150 hover:text-blue-900" />
        )}
        <div className="grow-1 font-hvLite text-sm font-normal leading-4">{label}</div>
      </div>
    </div>
  </label>
);

export default RadioTab;
