import InPlaceMessage from 'styleguide/components/InPlaceMessage/InPlaceMessage';
import cn from 'classnames';
import { FormikErrors, FormikTouched, FormikValues, getIn } from 'formik';
import * as React from 'react';
import { ErrorMessage } from 'styleguide/components/forms';

import RadioTab from './RadioTab';

interface Props {
  className?: string;
  children?: React.ReactNode;
  errors?: FormikErrors<FormikValues>;
  name?: string;
  touched?: FormikTouched<FormikValues>;
  Note?: React.ReactNode;
  inPlaceError?: boolean;
  hideErrorMessage?: boolean;
}

const RadioTabs = ({
  className,
  children,
  errors,
  name,
  touched,
  Note,
  inPlaceError,
  hideErrorMessage,
}: Props) => (
  <>
    <div className={cn('flex w-full flex-row flex-nowrap', className)}>{children}</div>
    {inPlaceError && !hideErrorMessage && (
      <InPlaceMessage messageType="error" touched={getIn(touched, name)} message={getIn(errors, name)} />
    )}
    {!inPlaceError && !hideErrorMessage && getIn(touched, name) && getIn(errors, name) && (
      <ErrorMessage message={getIn(errors, name)} />
    )}
    {!!Note && <div className="mt-2">{Note}</div>}
  </>
);

RadioTabs.Tab = RadioTab;

export default RadioTabs;
